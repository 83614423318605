////////////////////////////////
//Variables//
////////////////////////////////

$novo-orange: #f2b442;

////////////////////////////////
//Semantic//
////////////////////////////////
.wrapper {
  padding-top: 40px;
  padding-bottom: 55px;
}

.header-menu.ui.menu .logo {
  width: 7em !important;
  //margin-right: 1.5em !important;
}

.header-menu.ui.menu .gravatar {
  width: inherit;
}

.header-menu.ui.menu {
  border-bottom: 1px solid;
  height: 40px;
}

.main.container {
  margin-top: 1em;
}

.messages > .divider:first-child {
  display: none;
}

.message > .ui.ribbon.label {
  left: calc(-1rem - 1.3rem) !important;
}

////////////////////////////////
//Text//
////////////////////////////////
.mini.text {
  font-size: 0.71428571rem;
  line-height: 1.2em;
}

.tiny.text {
  font-size: 0.85714286rem;
  line-height: 1.2em;
}

.small.text {
  font-size: 0.92857143rem;
  line-height: 1.2em;
}

.ribbon.tiny.status.label,
.ribbon.mini.status.label {
  padding: 0.4833em 0.733em;
}

.ribbon.tiny.status.label .truncate,
.ribbon.mini.status.label .truncate {
  text-align: center;
  width: 60px;
}

.truncate {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2em;
}

.color.novo-orange {
  color: $novo-orange;
}

////////////////////////////////
//Grid//
////////////////////////////////
.ui.stackable.vertically.compact.grid > .row > .column,
.ui.grid.vertically.compact > .row,
.ui.grid.vertically.compact > .column {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.ui.stackable.very.vertically.compact.grid > .row > .column,
.ui.grid.very.vertically.compact > .row,
.ui.grid.very.vertically.compact > .column {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.margin.bottom {
  margin-bottom: 1rem;
}

////////////////////////////////
//Wireframe//
////////////////////////////////
.wireframe.image {
  opacity: 0.5;
}

////////////////////////////////
//Pace//
////////////////////////////////
.pace .pace-progress {
  top: 0;
  background: $novo-orange;
}

//.pace .pace-progress-inner {
//  box-shadow: 0 0 10px $novo-orange, 0 0 5px $novo-orange;
//}

.pace .pace-activity {
  top: 14px;
  border-top-color: $novo-orange;
  border-left-color: $novo-orange;
}

////////////////////////////////
//Segments//
////////////////////////////////

////////////////////////////////
//Typeography//
////////////////////////////////
.text-muted {
  color: rgba(0, 0, 0, 0.4);
}
.html.notes p {
  margin-bottom: 0;
}

////////////////////////////////
//Pagination//
////////////////////////////////
.dataTable .ui.pagination.menu .item {
  min-width: 1em;
}

////////////////////////////////
//Contact Labels//
////////////////////////////////
//.ui.contact.active.label {
//  border-left-color: #DB2828 !important;
//  border-left-width: 3px;
//}

////////////////////////////////
//Project Search//
////////////////////////////////
.header-menu .mobile.hidden .project.search .input,
.header-menu .mobile.hidden .project.search .results {
  width: 300px;
}

#side-menu .project.search .input,
#side-menu .project.search .results {
  width: 100% !important;
}

////////////////////////////////
//Footer//
////////////////////////////////
.ui.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

////////////////////////////////
//Utility//
////////////////////////////////
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

@import "semantic_retarget";
@import "responsive_helpers";
@import "home";
@import "messages";
@import "novo_colors";
//@import "dropzone";

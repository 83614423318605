.ui.form select.select {
  height: 38px;
  padding: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  visibility: visible;
}

.ui.grid > .header.row {
  border-top: 2px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  background: #f8f8f9;
}

.ui.grid > .header.row .header {
  font-weight: 700;
  font-size: 1.14285714em;
}

.ui.contact.vertical.segment {
  padding-top: 0;
  padding-bottom: 0.5em;
}

.ui.mini.form .ui.dropdown .menu > .item {
  font-size: 0.78571429rem;
}

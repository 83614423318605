.masthead h1.ui.header {
  margin-top: 2em;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
}

.masthead.segment {
  min-height: 480px;
}

.alternate.stripe.ui.segment {
  background-color: #f2f3f5;
}

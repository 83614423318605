/*--- Buttons ---*/

.ui.novo-orange.buttons .button,
.ui.novo-orange.button {
  background-color: #f2b442;
  color: #ffffff;
  text-shadow: none;
  background-image: none;
}

.ui.novo-orange.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.novo-orange.buttons .button:hover,
.ui.novo-orange.button:hover {
  background-color: #faad20;
  color: #ffffff;
  text-shadow: none;
}

.ui.novo-orange.buttons .button:focus,
.ui.novo-orange.button:focus {
  background-color: #ffa90c;
  color: #ffffff;
  text-shadow: none;
}

.ui.novo-orange.buttons .button:active,
.ui.novo-orange.button:active {
  background-color: #efa112;
  color: #ffffff;
  text-shadow: none;
}

.ui.novo-orange.buttons .active.button,
.ui.novo-orange.buttons .active.button:active,
.ui.novo-orange.active.button,
.ui.novo-orange.button .active.button:active {
  background-color: #ffaf1b;
  color: #ffffff;
  text-shadow: none;
}

/* Basic */
.ui.basic.novo-orange.buttons .button,
.ui.basic.novo-orange.button {
  box-shadow: 0px 0px 0px 1px #f2b442 inset !important;
  color: #f2b442 !important;
}

.ui.basic.novo-orange.buttons .button:hover,
.ui.basic.novo-orange.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #faad20 inset !important;
  color: #faad20 !important;
}

.ui.basic.novo-orange.buttons .button:focus,
.ui.basic.novo-orange.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #ffa90c inset !important;
  color: #faad20 !important;
}

.ui.basic.novo-orange.buttons .active.button,
.ui.basic.novo-orange.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #ffaf1b inset !important;
  color: #efa112 !important;
}

.ui.basic.novo-orange.buttons .button:active,
.ui.basic.novo-orange.button:active {
  box-shadow: 0px 0px 0px 1px #efa112 inset !important;
  color: #efa112 !important;
}

.ui.buttons:not(.vertical) > .basic.novo-orange.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */
.ui.inverted.novo-orange.buttons .button,
.ui.inverted.novo-orange.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #f2b442 inset !important;
  color: #f2b442;
}

.ui.inverted.novo-orange.buttons .button:hover,
.ui.inverted.novo-orange.button:hover,
.ui.inverted.novo-orange.buttons .button:focus,
.ui.inverted.novo-orange.button:focus,
.ui.inverted.novo-orange.buttons .button.active,
.ui.inverted.novo-orange.button.active,
.ui.inverted.novo-orange.buttons .button:active,
.ui.inverted.novo-orange.button:active {
  box-shadow: none !important;
  color: #ffffff;
}

.ui.inverted.novo-orange.buttons .button:hover,
.ui.inverted.novo-orange.button:hover {
  background-color: #faad20;
}

.ui.inverted.novo-orange.buttons .button:focus,
.ui.inverted.novo-orange.button:focus {
  background-color: #ffa90c;
}

.ui.inverted.novo-orange.buttons .active.button,
.ui.inverted.novo-orange.active.button {
  background-color: #ffaf1b;
}

.ui.inverted.novo-orange.buttons .button:active,
.ui.inverted.novo-orange.button:active {
  background-color: #efa112;
}

/* Inverted Basic */
.ui.inverted.novo-orange.basic.buttons .button,
.ui.inverted.novo-orange.buttons .basic.button,
.ui.inverted.novo-orange.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #ffffff !important;
}

.ui.inverted.novo-orange.basic.buttons .button:hover,
.ui.inverted.novo-orange.buttons .basic.button:hover,
.ui.inverted.novo-orange.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #faad20 inset !important;
  color: #f2b442 !important;
}

.ui.inverted.novo-orange.basic.buttons .button:focus,
.ui.inverted.novo-orange.basic.buttons .button:focus,
.ui.inverted.novo-orange.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #ffa90c inset !important;
  color: #f2b442 !important;
}

.ui.inverted.novo-orange.basic.buttons .active.button,
.ui.inverted.novo-orange.buttons .basic.active.button,
.ui.inverted.novo-orange.basic.active.button {
  box-shadow: 0px 0px 0px 2px #ffaf1b inset !important;
  color: #f2b442 !important;
}

.ui.inverted.novo-orange.basic.buttons .button:active,
.ui.inverted.novo-orange.buttons .basic.button:active,
.ui.inverted.novo-orange.basic.button:active {
  box-shadow: 0px 0px 0px 2px #efa112 inset !important;
  color: #f2b442 !important;
}

/*--- Label ---*/

.ui.orange.labels .label,
.ui.orange.label {
  background-color: #f2b442 !important;
  border-color: #f2b442 !important;
  color: #ffffff !important;
}

/* Link */
.ui.orange.labels .label:hover,
a.ui.orange.label:hover {
  background-color: #faad20 !important;
  border-color: #faad20 !important;
  color: #ffffff !important;
}

/* Corner */
.ui.orange.corner.label,
.ui.orange.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.ui.orange.ribbon.label {
  border-color: #efa112 !important;
}

/* Basic */
.ui.basic.orange.label {
  background-color: #ffffff !important;
  color: #f2b442 !important;
  border-color: #f2b442 !important;
}

.ui.basic.orange.labels a.label:hover,
a.ui.basic.orange.label:hover {
  background-color: #ffffff !important;
  color: #faad20 !important;
  border-color: #faad20 !important;
}
